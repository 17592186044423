<script>
  import { FormTheme } from "@gl/design-system";
  import { Navbar, Callout } from "./components/index";
  import { Router, Route, navigate } from "svelte-routing";
  import {
    Login,
    Contracts,
    Home,
    Forbidden,
    TemporarilyUnavailable,
  } from "./pages/index";
  import {
    user,
    callout,
    contract,
    language,
    navbarItems,
    hasApp,
  } from "./stores/index";
  import { onMount, setContext } from "svelte";
  import "@gl/design-system/css";
  import "@gl/bundescloud-player/dist/latest/app.css";
  import "./app.css";
  import { useStack } from "@gl/svelte/usables";
  import { writable } from "svelte/store";
  import { addMessages, init } from "svelte-i18n";
  import en from "./i18n/en.json";
  import de from "./i18n/de.json";
  import EventManagerContext from "./EventManagerContext.svelte";
  import AccessManagerContext from "./AccessManagerContext.svelte";
  import Root from "./pages/Root.svelte";
  import Page404 from "./pages/Page404.svelte";
  import { UsersRepository } from "./repositories/index";

  addMessages("en", en);
  addMessages("de", de);
  init({ fallbackLocale: "en", initialLocale: $language });

  import getFile from "./services/xml";
  import ContentManagerContext from "./ContentManagerContext.svelte";
  const config = getFile("/config.json");
  setContext("config", config);
  setContext("FormTheme", FormTheme);

  const itemsStore = writable([
    "access-manager",
    "channel-manager",
    "content-manager",
  ]);
  const navbarItemsStore = useStack("GLOBAL_FE_NAVBAR_ITEMS", itemsStore);
  $: if (navbarItemsStore) navbarItems.set($navbarItemsStore);

  onMount(() => {
    if (!$user && $contract) {
      navigate(`/contracts/${$contract.id}`);
    } else if ($user && !$contract) {
      navigate("/contracts");
    }
  });
</script>

<svelte:head>
  <title>{config.NAME}</title>
</svelte:head>

<div class="root">
  <Router>
    {#if $user && $contract}
      <Navbar />
    {/if}

    {#if $user}
      <Route path="/contracts" component={Contracts} />
    {:else}
      <Route path="/" component={Login} />
    {/if}

    {#if $user && $contract && $user.apps}
      <div class="content-container">
        <Route path="/" component={Root} />

        <Route path="/contracts/:CON/" component={Home} />

        <Route component={Page404} path="/404" />
        <Route component={Forbidden} path="/forbidden" />
        <Route
          component={TemporarilyUnavailable}
          path="/temporarily-unavailable"
        />

        {#if $hasApp("access-manager")}
          <Route path="/access-manager/*">
            <AccessManagerContext />
          </Route>
        {/if}

        {#if $hasApp("channel-manager")}
          <Route path="/channel-manager/*">
            <EventManagerContext />
          </Route>
        {/if}

        {#if $hasApp("content-manager")}
          <Route path="/content-manager/contracts/:CON/*">
            <ContentManagerContext />
          </Route>
        {/if}
      </div>
    {:else}
      <Route path="/" component={Login} />
    {/if}
  </Router>

  <Callout />
</div>

<!-- <div class="root">
  <div class="header">header</div>
  <div class="content">
    <div class="content-child">content-child</div>
  </div>
</div> -->

<style>
  .root {
    display: grid;
    grid-template-rows: 64px 1fr;
    align-items: start;
  }

  .content-container {
    height: 100vh;
    max-width: 100vw;
  }
</style>
