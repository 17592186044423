<script>
  import {
    Navbar,
    NavbarItem,
    NavbarZone,
    DropdownItem,
    DropdownList,
    DropdownDivider,
    Icon,
    Button,
    Text,
    Flex,
    FlexItem,
  } from "@gl/design-system";
  import { Link } from "svelte-routing";
  import {
    _,
    user,
    contract,
    language,
    url,
    navbarItems,
    Auth,
    hasApp,
  } from "../stores/index";

  import { format } from "date-fns";
  import { locale } from "svelte-i18n";
  import {
    faBuilding,
    faArrowRight,
    faCopy,
  } from "@fortawesome/pro-regular-svg-icons";
  import { onMount, onDestroy, getContext } from "svelte";
  import { IDP } from "../services/index";
  const authClass = new Auth(user);
  const { isAuthorized } = authClass;

  async function handleLanguage(lang) {
    locale.set(lang);
    language.set(lang);
    document.documentElement.lang = lang;
  }

  async function logOut() {
    try {
      await IDP.logout();
    } catch (e) {
      error = e.message ?? e.statusText;
    }
  }

  function showApp(navbarItem) {
    if (!$user.apps?.some((ua) => ua.id === navbarItem)) return false;

    if (navbarItem === "channel-manager" && !$isAuthorized("channel_access"))
      return false;
    if (navbarItem === "access-manager" && !$isAuthorized("user_read"))
      return false;
    return true;
  }

  let now = new Date();
  function updateDate() {
    now = new Date();

    if (!user || now > new Date($user?.token?.exp)) logOut();
  }
  const interval = setInterval(updateDate, 1000);

  const config = getContext("config");

  onMount(() => {
    if (!$user.token) return true;
  });

  onDestroy(() => {
    clearInterval(interval);
  });
</script>

<div class="navbar-container">
  <Navbar>
    <div slot="navbar-main">
      <Link to={`/contracts/${$contract.id}`} let:active>
        <span
          class={active
            ? "mr-2 level-6 color-white is-selected"
            : "mr-2 level-6 color-white"}
        >
          {config.NAME}
        </span>
      </Link>
    </div>
    <NavbarZone position="start">
      <span class="vertical-line" />
      {#each $navbarItems || [] as navbarItem}
        {#if $user && showApp(navbarItem)}
          <div class="navbar-items">
            <NavbarItem type="content">
              <Link to={`/${navbarItem}/contracts/${$contract.id}`}>
                <span
                  class="gl-navbar-button"
                  class:is-selected={$url.pathname.includes(navbarItem)}
                >
                  {$_(navbarItem)}
                </span>
              </Link>
            </NavbarItem>
          </div>
        {/if}
      {/each}
    </NavbarZone>
    <NavbarZone position="end">
      <div class="time-date" role="timer">
        <p class="time">{now.toLocaleTimeString()}</p>
        <p class="date">
          {format(now, "dd.MM.yyyy")}
        </p>
        <div class="tooltip">
          {format(now, "'GMT' X")}
        </div>
      </div>

      <div class="vertical-line ml-1" />

      <button
        class={$language !== "de"
          ? "gl-navbar-item"
          : "gl-navbar-item is-disabled"}
        on:click={() => {
          handleLanguage("en");
        }}>EN</button
      >
      <button
        class={$language === "de"
          ? "gl-navbar-item"
          : "gl-navbar-item is-disabled"}
        on:click={() => {
          handleLanguage("de");
        }}
        >DE
      </button>

      {#if config.SUPPORT_LINK}
        <a
          href={config.SUPPORT_LINK}
          class="gl-navbar-item ml-4"
          target="_blank"
        >
          {$_("common.help")}
        </a>
      {/if}

      <div class="vertical-line ml-1" />

      <NavbarItem type="dropdown">
        <span slot="label">
          <Icon name="User" />
          <span>{$user?.data.email}</span>
        </span>

        <DropdownList on:select>
          {#if $contract}
            <DropdownItem>
              <Text>
                <Icon icon={faBuilding} />
                <b>{$contract.name}</b>
              </Text>
              <Flex>
                <FlexItem>
                  <div
                    class="ml-1 mr-1 color-text-light contract-id"
                    title={$contract.id}
                  >
                    <b class="mr-1">ID:</b>{$contract.id}
                  </div>
                </FlexItem>
                <FlexItem>
                  <Button
                    on:click={() => navigator.clipboard.writeText($contract.id)}
                    transparent
                    style="padding:0px; margin-top:-5px"
                    title="copy-button"
                  >
                    <Icon icon={faCopy} />
                  </Button>
                </FlexItem>
              </Flex>
            </DropdownItem>
          {/if}

          <DropdownDivider />
          {#if ($user?.contracts.length > 1 || $user?.data.isMaster) && $contract}
            <DropdownItem>
              <div class="color-grey" style="font-size: 0.8em">
                {$_("contract.switch")}:
              </div>
              <div class="fake-button">
                <Link to="/contracts">{$_("contract.select")}</Link>
                <Icon icon={faArrowRight} />
              </div>
            </DropdownItem>
            <DropdownDivider />
          {/if}

          <div
            class="gl-dropdown-item"
            class:is-selected={$url.pathname.endsWith("me")}
          >
            <Link
              to={`access-manager/contracts/${$contract.id}/users/${$user.data.id}/data`}
            >
              {$_("users.me.profile")}
            </Link>
          </div>
          <DropdownDivider />

          <DropdownItem>
            <Button on:click={logOut}>{$_("common.logout")}</Button>
          </DropdownItem>

          <DropdownDivider />

          <DropdownItem>
            <Flex class="justify-content-center">
              <p class="color-text-light" style="font-size:14px">
                {$_("common.version")}: {import.meta.env.VITE_LATEST_TAG}
              </p>
            </Flex>
          </DropdownItem>
        </DropdownList>
      </NavbarItem>
    </NavbarZone>
  </Navbar>
</div>

<style>
  .navbar-container {
    height: 64px;
    position: sticky;
    top: 0;
    align-self: start;
    background-color: #fff;
    z-index: 40;
  }

  .vertical-line {
    width: 36px;
    height: 0px;
    opacity: 0.5;
    border: 0.8px solid #999999;
    transform: rotate(90deg);
    margin-left: -15px;
  }

  .fake-button {
    border: 1px solid #00678d;
    margin-left: 10px;
    margin-bottom: 10px;
    width: 85%;
    font-weight: bold;
    color: #00678d;
  }

  .time {
    font-family: "Roboto";
    font-size: 16px;
    text-align: right;
  }

  .date {
    font-size: 12px;
    color: #bfbfbf;
    text-align: right;
  }

  .time-date:hover .tooltip {
    display: block;
  }

  .time-date {
    position: relative;
  }

  .tooltip {
    background-color: white;
    border-radius: 4px;
    color: #4d4d4d;
    text-align: center;
    right: 0;
    position: absolute;
    padding: 2px 8px;
    font-size: 14px;
    display: none;
    box-shadow:
      0px 8px 16px -2px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px rgba(0, 0, 0, 0.02);
  }

  .contract-id {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 146px;
  }

  /* tablet displays */
  @media screen and (max-width: 1023px) {
    .vertical-line {
      display: none;
    }

    .time-date {
      display: none;
    }

    .navbar-items {
      display: none;
    }
  }
</style>
